<template>
  <Card
    :class="[
      {
        background: hasBackground,
        inverted: inverted,
        'align-right': alignRight,
        'label-align-right': labelAlignRight,
        'content-align-right': contentAlignRight,
        small: isSmall,
        large: isLarge,
        inline: inline,
        'full-width': fullWidth,
        disabled: disabled,
        wrap: wrap,
        vertical: vertical,
        'vertical-align': verticalAlign,
        grid: grid,
        outlined: outlined,
        'label-align-top': labelAlignTop,
      },
    ]"
    :style="cardStyles"
    :aria-label="label || tag"
  >
    <template #title v-if="label || tag">
      <span
        class="label"
        :class="[{ 'p-text-nowrap': !labelWrap }, labelClass]"
        :style="{ color: labelColor }"
      >
        {{ label }}
      </span>
      <Tag :value="tag" severity="info" class="p-tag-sm p-ml-2" v-if="tag" />
    </template>
    <template #content>
      <span class="value" :class="[{ 'p-text-nowrap': !contentWrap }, contentClass]">
        <slot v-if="!loading" />
      </span>
      <Skeleton v-if="loading && !hasBackground" height="1.15em" :width="inline ? '10ch' : 'auto'" />
      <Skeleton
        v-else-if="loading && hasBackground"
        class="foreground-skeleton"
        height="100%"
        borderRadius="var(--border-radius)"
      />
    </template>
  </Card>
</template>

<script>
import Card from "primevue/card";
import Skeleton from "primevue/skeleton";
import Tag from "primevue/tag";

export default {
  name: "KeyValueCard",
  components: {
    Card,
    Skeleton,
    Tag,
  },
  props: {
    label: String,
    labelAlign: {
      type: String,
      default: "left",
    },
    labelWrap: {
      type: Boolean,
      default: false,
    },
    contentWrap: {
      type: Boolean,
      default: true,
    },
    labelColor: {
      type: String,
    },
    labelClass: {
      type: String,
      default: "",
    },
    contentClass: {
      type: String,
      default: "",
    },
    background: {
      type: [Boolean, String],
      default: false,
    },
    inverted: Boolean,
    align: {
      type: String,
      default: "left",
    },
    contentAlign: {
      type: String,
      default: "right",
    },
    size: {
      type: String,
      default: "medium",
    },
    inline: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
    loading: Boolean,
    wrap: Boolean,
    vertical: Boolean,
    tag: String,
    verticalAlign: String,
    grid: String,
    outlined: Boolean,
  },
  computed: {
    alignRight() {
      return this.align === "right";
    },
    labelAlignRight() {
      return this.labelAlign === "right";
    },
    labelAlignTop() {
      return this.labelAlign === "top"
    },
    contentAlignRight() {
      return this.contentAlign === "right";
    },
    isSmall() {
      return this.size === "small";
    },
    isLarge() {
      return this.size === "large";
    },
    hasBackground() {
      return !!this.background;
    },
    cardStyles() {
      return {
        ...(this.hasBackground && { background: this.background }),
        ...(this.grid && { "--grid-gutter": this.grid }),
      };
    },
  },
};
</script>

<style scoped lang="scss">
.p-card {
  display: inline-flex;
  margin-right: 1em;
  border: none;

  &.disabled {
    opacity: 0.5;
  }

  &:last-child {
    margin-right: 0 !important;
  }

  &.small {
    font-size: 0.833334em;
  }

  &.large {
    font-size: 1.3em;

    :deep() {
      .p-card-title {
        margin-bottom: 0.2em;
      }
    }
  }
 
  &.inline {
    &.label-align-top {
      :deep() {
        .p-card-body {
          align-items: flex-start;
        }
      }
    }
    :deep() {
      .p-card-body {
        display: flex;
        align-items: center;

        .p-card-title {
          flex: 1;
          margin: 0 0.5em 0 0;
        }
      }
    }
  }

  &.full-width {
    display: flex;
    margin-right: 0 !important;
    :deep() {
      .p-card-body {
        .p-card-title {
          flex: 0 1 30%;
        }
        .p-card-content {
          flex: 1;
        }
      }
    }
  }

  &.background {
    background: var(--key-value-card-bg);
    backdrop-filter: blur(12px);
  }

  &.inverted {
    background: var(--primary-color);
    color: white;

    :deep() {
      .p-card-title {
        color: var(--white-gray);
      }
    }
  }

  &:not(.background):not(.inverted):not(.outlined) {
    background: none;

    &:not(.align-right) {
      margin-right: 1.5em;
    }

    :deep() .p-card-body {
      padding: 0;
    }
  }

  &:not(.inverted) :deep() .p-card-title {
    color: var(--key-value-card-title-color);
  }

  &.align-right {
    margin-left: auto;
    margin-right: 0;
    text-align: right;
  }

  &.label-align-right {
    :deep() .p-card-title {
      justify-content: flex-end;
      text-align: right;
    }
  }

  &.content-align-right {
    :deep() .p-card-content {
      justify-content: flex-end;
      text-align: right;
    }
  }

  &.wrap {
    :deep() {
      .p-card-content {
        white-space: initial;

        .party {
          align-items: baseline;
        }
      }
    }
  }

  &.vertical {
    :deep() {
      .p-card-content {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &.align-right {
      :deep() {
        .p-card-content {
          align-items: flex-end;

          & > * {
            margin-right: 0;
          }
        }
      }
    }
  }

  &.vertical-align {
    :deep() {
      .p-card-content {
        align-items: baseline;
      }
    }
  }

  &.grid {
    :deep() {
      .p-card-body {
        .p-card-title {
          flex: 0 0 var(--grid-gutter);
          margin: 0;
        }
        .p-card-content {
          width: calc(100% - var(--grid-gutter));
        }
      }
    }
  }

  &.outlined {
    background: transparent;
    border: 1px solid var(--text-color-secondary);
  }

  :deep() {
    .p-card-body {
      flex: 1;
      padding: 0.5em;
    }
    .p-card-title {
      font-size: 1em;
      font-weight: normal;
      line-height: 1;
      margin-bottom: 0.4rem;
    }
    .p-card-content {
      display: flex;
      align-items: center;
      font-size: 1em;
      font-weight: 500;
      padding: 0;
      white-space: normal;

      & > .value {
        display: contents;

        & > .avatar {
          margin: -0.25em 0.5em -0.25em 0;
        }
      }

      .p-skeleton {
        font-size: 1em;
        margin-top: 0.2em;
        margin-bottom: 0.15em;
      }
    }
  }
}
</style>
